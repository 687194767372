@import '../vars.scss';

#pricing{

  padding: 0px 10vw 80px;

  .svgTitle{
    color: $primary;

    padding-left: 200px;
    padding-right: 200px;

    @include small{
      padding-left: 20%;
      padding-right: 20%;
    }
  
    polygon, path{
      fill: white;
    }

    margin-bottom: 60px;
  }

  .prices{
    justify-content: space-evenly;
  }

  .priceOption{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 20px 40px;
    max-width: 270px;
    min-width: 220px;
    background-color: white;
    box-shadow: $boxShadowSoft;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    .header{
      justify-content: space-between;
      padding: 10px 15px;
      font-size: 16pt;
      color: white;
      box-shadow: $boxShadowSoft;

      span{
        font-size: 10pt;
      }
    }

    .text{
      padding: 10px 15px;
      font-size: 10pt;
      color: darken($grey, 5);

      b{
        font-size: 14pt;
        color: $dark;
      }
    }

    .vCenter{
      padding: 5px 15px 20px;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    
    .btn{
      background-color: $primary;
      color: white;
      margin: 0 auto 20px;
    }
  }

  .note{
    margin-top: 10px;
    font-size: 10pt;
    color: $grey;

    a{
      color: lighten($color: $primary, $amount: 20);
      text-decoration: none;
    }
  }
}