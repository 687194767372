@import '../vars.scss';

#brag{
  position: relative;
  margin-top: 100px;
  padding: 0;
  color: $dark;

  // @include small{
  //   width: 90vw;
  //   padding: 40px 30px;
  // }
  .mainRow{
    justify-content: space-between;
  }

  .contentCard{
    @include hideOnSmall;

    position: relative;
    align-items: center;
    display: flex;
    min-width: 45vw;

    .svgBackground{
      z-index: 0;
      position: absolute;
  
      left: 0;
      top: -15%;
  
      width: 110%;
      height: 130%;
  
      svg{
        width: 100%;
        height: 100%;
      }
    }

    .content{
      color: $primary;
      position: relative;
      z-index: 1;
      align-items: stretch;
      width: 100%;
      padding: 0 3vw;
      justify-content: space-evenly;

      .col{
        justify-content: space-evenly;
      }

      .statsText{
        text-align: left;
        margin: 20px 0;
        font-size: 19pt;
        line-height: 1;

        span{
          font-size: 14pt;
        }
      }

      .vehicle{
        text-align: center;

        img{
          width: 80px;

          &.larger{
            width: 200px;
          }
        }
      }

      .multiplyBox{
        align-self: center;
        margin: 0 30px;

        .multiply{
          line-height: 0.6;
          border: 2px solid $primary;
          border-radius: 100px;
          padding: 26px 10px;
          width: fit-content;
          width: -moz-fit-content;
          margin: auto;
  
          text-align: center;
  
          font-size: 22pt;
  
          span{
            font-size: 13pt;
          }
        }
      }
    }
  }

  .right{
    margin-right: 15vw;
    margin-left: 10vw;
    color: white;

    @include medium{
      margin-right: 5vw;
      margin-left: 5vw;
    }

    @include small{
      margin: 0;
      padding: 0 30px;
    }

    .desc{
      .text{
        margin: 0;
        margin-bottom: 8px;
        max-width: 400px;
        font-size: 14pt;
      }

      .points {
        margin: 0;
        margin-bottom: 8px;
        line-height: 1.6;
      }

      .linkRow{
        align-items: center;

        @include small{
          .secondLink{
            margin-top: 10px;
          }
        }
      }

      .btn{
        color: $primary;
        background-color: white;
        margin-right: 30px;
      }

      a{
        color: white;
        float: rigth;
      }

      &.mb{
        margin-bottom: 80px;

        @include small{
          margin-bottom: 40px;
        }
      }
    }
  }
}