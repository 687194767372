@import '../vars.scss';

#featuresTitle{
  margin-top: 180px;
  justify-content: space-between;
  align-items: center;
  
  .title{
    font-size: $titleSize;
    color: white;

    @include small{
      font-size: $titleSize - 3pt;
      padding: 0 10px;
    }
  }
  .svg{
    polygon, path{
      fill: white;
    }

    width: 30%;
    height: 65px;
    transform: translateY(1px);

    @include small{
      width: 20%;
      height: 70px;
    }

    svg{
      height: 100%;
      width: 100%;
    }
  }
}

#features{
  background-color: white;
  color: $primary;
  padding: 50px 15vw 70px;

  a{
    color: lighten($color: $primary, $amount: 20);
    text-decoration: none;
  }

  @include medium{
    padding: 50px 5vw 0;
    padding-bottom: 30px;
  }

  font-weight: 400;

  img{
    display: block; 
    margin: 50px auto 0;
    max-width: 100%;

    @include hideOnSmall;
  }

  .selection{
    margin-top: 10px;
    flex-wrap: wrap;

    .element{
      font-size: 42px;
      opacity: 0.5;
      margin-right: 30px;
      cursor: pointer;
  
      &:hover{
        opacity: 0.7;
      }
  
      &.active {
        border-bottom: 3px solid $primary;
        opacity: 1;
      }
    }
  }

  .text1{
    font-weight: 500;
    font-size: 15pt;
    max-width: 500px;
    margin: 30px 0;
  }

  .text2{
    width: 80%;
    line-height: 1.3;
    opacity: 1;
    margin: 30px 0 50px;
    font-size: 13pt;
  }

  .text3{
    line-height: 1.5;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    
    article{
      width: 250px;
      padding-right: 2rem;
      margin: 0.7rem 0;

      h4{
        margin-top: 0;
        margin-bottom: 0;

        a{
          color: unset;
        }
      }

      p{
        margin-top: 0.2rem;
      }
    }
  }
}
