@import "../vars.scss";

#introBackground {
  background-image: linear-gradient(120deg, #2b99ff, #002e58);
  padding-top: 1px;
}

#intro {
  z-index: 3;
  position: relative;

    /* */

    .banner {
      color: #F2F2F2;
      /*padding: 2px 8px;*/
      padding:2px 90px 2px 8px;
      text-overflow: clip;
      unicode-bidi: isolate;
      cursor: pointer;
      display:block;
      page-break-after: always;
      break-after: always; 


      span {
        display :inline;
        padding: 2px 8px;
      }

      a {color:#F2F2F2}

      a:hover {
        text-decoration: underline !important;;
      }
    }

    .banner-inverse {
      background-color: #F0FE3B;
      color: #0C315E;
      transform: skew(-15deg);
      padding: 2px 8px;
      text-overflow: clip;
      unicode-bidi: isolate;
      cursor: pointer;
      display:block;
      font-weight:600;

      span {
        display :block;
        padding: 2px 8px;
        transform: skew(15deg);
        background: transparent;
        line-height: 1.1;
      }

      a {color:#0C315E !important;}

      a:visited {color:#0C315E !important;}

      a:hover {
        text-decoration: underline !important;;
      }
    }

    .banner-inline {
      color: #F2F2F2;
      padding: 2px 8px;
      text-overflow: clip;
      unicode-bidi: isolate;
      cursor: pointer;
      display:inline;

      span {
        display :inline;
        padding: 2px 8px;
      }
    }
  
    /* */

  .intro-title {
    font-size :96px !important;
    font-weight:600;
    font-family: 'JetBrains Mono';
    display:block;
    text-align: center;
    margin: auto;

    @include medium {
      font-size :66px !important;
    }

    @include small {
      font-size :46px !important;
    }
  }

  .intro-sub-title {
    font-size:32px  !important;

    @include medium {
      font-size :26px !important;
    }

    @include small {
      font-size :22px !important;
    }
  }

  #navBar {
    width: 80vw;
    margin: 0 auto;
    margin-top: 40px;
    color: white;

    display: flex;
    align-items: center;

    .logoBar{
      display: flex;
      align-items: center; 

      &.mendiumAndDown{
        display: none;

        @include medium {
          display: flex;
          width: 100%;
          margin-top: 1rem;
        } 
      }

      &.desktop{
        @include hideOnMedium;
      }

      img {
        height: 40px;
      }

      .title {
        margin: 0 20px;
        font-size: 20pt;
        opacity: 0.6;
      }
    }

    .actions {
      display: flex;
      margin-left: auto;

      .login {
        border: 1px solid white;
      }

      .register {
        margin-left: 15px;
        background-color: white;
        color: $primary;
      }
    }
  }


  #entry {
    width: 90vw;
    margin-top: 40px;
    margin-left: auto;
    min-height: 65vh;

    @include small {
      min-height: 40vh;
    }

    display: flex;
    align-items: center;

    .title {
      color: white;
      line-height: 1.5;
      font-weight: normal;
      font-size: 35pt;

      a {
        color: white;
        text-decoration: none;
      }
      a:hover {
        color: #a8d0f6;
      }
      a:visited {
        color: white;
      }
      a:active {
        color: white;
      }

      @include medium {
        font-size: 30pt;
      }
    }

    .text {
      margin-left: auto;
      position: relative;
      width: 50%;
      color: $primary;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include hideOnSmall;

      .multiply {
        z-index: 1;
        line-height: 1;
        border: 2px solid $primary;
        border-radius: 100px;
        padding: 15px;
        text-align: center;
        font-size: 24pt;

        span {
          font-size: 15pt;
        }
      }

      .textStack {
        z-index: 1;
        img {
          height: 90px;
          margin: 25px 0;
          max-width: 80%;

          &.larger {
            height: 180px;
            max-width: 90%;
          }
        }

        .statsText {
          font-size: 20pt;
          line-height: 1;

          span {
            font-size: 15pt;
          }
        }
      }

      .svgMain {
        z-index: 0;
        position: absolute;

        right: 0;
        top: -25%;

        width: 120%;
        height: 150%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
