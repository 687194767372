@import '../vars.scss';

#opensource{
  background-color: white;
  padding: 0 10vw;

  .heading{
    color: $primary;
    font-size: $titleSize;
    text-align: center;
    padding: 80px 0 20px;
  }

  .svgTitle{
    margin-bottom: 60px;
    text-align: center;
  }

  &>.text{
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
  }

  .projects{
    padding: 80px 0 40px;
    justify-content: space-between;

    .project{

      margin: 0 20px 60px 0;
      max-width: 300px;
      min-width: 250px;
      display: flex;
      flex: 1;
      flex-direction: column;

      .title{
        font-size: 16pt;
        margin-bottom: 15px;
      }

      .desc{
        color: $grey;
        margin-bottom: 15px;
      }

      .btn{
        color: white;
        background-color: $primary;
        margin-top: auto;
      }
    }
  }
}